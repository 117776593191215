import { Directive, TemplateRef, inject } from "@angular/core";

@Directive({
  selector: "[accordionContent]",
  standalone: true,
})
export class AccordionContent {
  templateRef = inject<TemplateRef<any>>(TemplateRef);

  constructor() {}
}
